import isArray from 'lodash/isArray';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

function getNamespace(path) {
    return path[0] === '/' ? path.substring(1) : path;
}

export default function useTransLoader(path) {
    const namespaces = isArray(path)
        ? path.map(item => getNamespace(item))
        : getNamespace(path);
    const {t, i18n} = useTranslation(namespaces);

    useEffect(() => {
        i18n.loadNamespaces(namespaces);
    }, [i18n, namespaces]);

    return {
        t,
        i18n,
    };
}
