import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {Suspense, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Loader from '@teladoc/pulse/ui/Loader';
import Arg from '@livongo/arg';
import {useFeatureFlagContext} from '@livongo/utilities/system/featureFlag';
import StorageUtils from '@livongo/utilities/system/storage';
import {IS_PROD} from './config';
import SchedulingPage from './SchedulingPage/SchedulingPage';
import SchedulingFormV2 from './SchedulingForm/SchedulingFormV2';
import ConfirmationPage from './ConfirmationPage/ConfirmationPage';
import ConfirmationPageV2 from './ConfirmationPage/ConfirmationPageV2';
import SessionDetails from './sessionDetails/SessionDetails';
import ConfirmationPageMentalHealth from './ConfirmationPage/ConfirmationPageMentalHealth';
import UserInfoAPI from './UserInfo/userInfo-api';
import FooterPublic from './Footer/Footer';

const App = () => {
    const dispatch = useDispatch();
    const {updateFeatureFlag} = useFeatureFlagContext();
    const referrer = Arg('referrer');
    const isOneApp = Arg('oneapp') || Arg('oneAppSdk');
    const showFooter =
        !referrer || (referrer && !referrer.includes('mobile') && !isOneApp);
    const isMobile = Arg('mobile') === true;

    useEffect(() => {
        const uuidArg = Arg('u');

        if (
            window.location.hostname.includes('teladoc') ||
            Arg('ccmOneApp') ||
            isOneApp
        ) {
            document.title = 'Teladoc Health - CCM Coach Request';
        }

        if (uuidArg) {
            UserInfoAPI.getAuthToken(uuidArg).then(({data}) => {
                dispatch({
                    type: 'AUTH',
                    payload: {
                        uuid: uuidArg,
                        token: data,
                    },
                });
            });
        } else {
            const token = StorageUtils.get({
                key: 'access_token',
                type: 'cookie',
                useNative: true,
                parse: false,
            });
            const uuid = token
                ? JSON.parse(atob(token.split('.')[1])).sub.replace(
                      'user/liv/',
                      ''
                  )
                : '';

            if (token && uuid) {
                dispatch({
                    type: 'AUTH',
                    payload: {
                        token,
                        uuid,
                    },
                });
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isMobile) {
            dispatch({
                type: 'VIEW',
                payload: {
                    enableNewUi: true,
                    view: 'smallview',
                    calendarId: Arg('calendarId'),
                    mobile: true,
                },
            });
        } else {
            dispatch({
                type: 'VIEW',
                payload: {
                    enableNewUi: true,
                    view: 'fullview',
                    calendarId: Arg('calendarId'),
                    mobile: false,
                },
            });
        }
    }, [isMobile]); // eslint-disable-line react-hooks/exhaustive-deps

    // This window object is used to update FeatureFlag Toggle via console.
    if (!IS_PROD) {
        window.updateFeatureFlag = ({
            featureName,
            oneAppActive = false,
            legacyActive = false,
        }) => {
            return updateFeatureFlag({
                featureName,
                oneAppActive,
                legacyActive,
            });
        };
    }

    return (
        <Suspense fallback={<Loader />}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<SchedulingPage />} />
                    <Route path="/v2" element={<SchedulingPage />} />
                    <Route path="/v2/schedule" element={<SchedulingFormV2 />} />
                    <Route
                        path="/confirmation"
                        element={<ConfirmationPage />}
                    />
                    <Route
                        path="/v2/confirmation"
                        element={<ConfirmationPageV2 />}
                    />
                    <Route
                        path="/confirmationMentalHealth"
                        element={<ConfirmationPageMentalHealth />}
                    />
                    <Route
                        path="/SessionDetails"
                        element={<SessionDetails />}
                    />
                </Routes>
                {!isMobile && showFooter && (
                    <div>
                        <FooterPublic />
                    </div>
                )}
            </BrowserRouter>
        </Suspense>
    );
};

export default App;
