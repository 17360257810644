import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import TextInput from '@teladoc/pulse/ui/TextInput';
import PhoneInput from '@teladoc/pulse/ui/PhoneInput';
import Label from '@teladoc/pulse/ui/Label';
import useTransLoader from 'i18n/use-trans-loader';
import UserInfoAPI from './userInfo-api';
import css from './UserInfo.scss';

const UserInfo = ({newUi, isMobile}) => {
    const {t} = useTransLoader('userInfo');
    const dispatch = useDispatch();
    const {userData} = useSelector(state => state.userData);
    const {token, uuid} = useSelector(state => state.userData.auth);
    const [user, setUser] = useState(userData);
    const [fetched, setFetched] = useState(false);
    const defaultPhoneNumberHolder = '(000) 000-0000';

    function getUserData() {
        return (
            token &&
            uuid &&
            UserInfoAPI.schedulingData(token, uuid)
                .then(data => {
                    dispatch({
                        type: 'USER_DATA',
                        payload: data,
                    });

                    setUser(data);
                    setFetched(true);
                })
                .catch(({data}) => {
                    dispatch({
                        type: 'ERROR',
                        payload: JSON.stringify(data),
                    });
                    dispatch(UserInfoAPI.incorrectToken);
                })
        );
    }

    useEffect(() => {
        getUserData();
    }, [token, uuid]); // eslint-disable-line react-hooks/exhaustive-deps

    if (newUi === true) {
        return (
            <div className={css.root}>
                {fetched && (
                    <PhoneInput
                        id="phone"
                        showCountrySelect={false}
                        classNameRoot={css.userInfoTextInput}
                        label={
                            <Label aria-hidden={isMobile}>{t('phone')}</Label>
                        }
                        name="phone"
                        placeholder={t(defaultPhoneNumberHolder)}
                        defaultValue={user.phones[0]?.number ?? null}
                        required
                    />
                )}
            </div>
        );
    } else {
        return (
            <div className={css.root}>
                <TextInput
                    id="firstName"
                    classNameRoot={css.userInfoTextInput}
                    label={<Label>{t('firstName')}</Label>}
                    name="firstName"
                    placeholder={t('firstName')}
                    value={user.firstName}
                    readOnly
                />
                <TextInput
                    id="lastName"
                    classNameRoot={css.userInfoTextInput}
                    label={<Label>{t('lastName')}</Label>}
                    name="lastName"
                    placeholder={t('lastName')}
                    value={user.lastName}
                    readOnly
                />
                <TextInput
                    id="email"
                    classNameRoot={css.userInfoTextInput}
                    label={<Label>{t('email')}</Label>}
                    name="email"
                    placeholder={t('email')}
                    value={user.email}
                    readOnly
                />
                {fetched && (
                    <PhoneInput
                        id="phone"
                        classNameRoot={css.userInfoTextInput}
                        label={<Label>{t('phoneLabel')}</Label>}
                        name="phone"
                        placeholder={t('phone')}
                        defaultCountry={user.phones[0]?.countryCode}
                        defaultValue={user.phones[0]?.number}
                        readOnly
                    />
                )}
            </div>
        );
    }
};

UserInfo.propTypes = {
    newUi: PropTypes.bool,
};

export default UserInfo;
