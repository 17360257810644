import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import Form from '@teladoc/pulse/ui/Form';
import Arg from '@livongo/arg';
import SessionDetails from './SessionDetails';

const ConfirmationPageV2 = () => {
    const {view} = useSelector(state => state.userData);
    const appointmentData = useSelector(
        state => state.scheduler.appointmentData
    );
    const calendarId = Arg('calendarId');
    const location = useLocation();
    let viewType;

    viewType = Arg('view');

    if (view) {
        viewType = view?.view;
    }

    const isFullView = !viewType
        ? true
        : viewType?.toLowerCase() === 'fullview';

    useEffect(() => {
        const target = 'showSession=true';
        const search = `${location?.search}`;
        const found = search.includes(target);

        if (!found) {
            window.location.href = `/sessiondetails/${location?.search?.replace(
                'enableNewUi=true',
                ''
            )}`;
        }
    }, [location?.search]);

    return (
        <Form>
            <div>
                <SessionDetails
                    showHeading={isFullView}
                    showCancelButton={!isFullView}
                    showAccordion={isFullView}
                    calendarId={calendarId}
                    appointmentData={appointmentData}
                    dontShowLoader={view?.dontShowLoader}
                />
            </div>
        </Form>
    );
};

export default ConfirmationPageV2;
