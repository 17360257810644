export const PRODUCTION_SERVICES_IDS = {
    HEART_FAILURE_INITIAL_ENGLISH: '673563',
    HEART_FAILURE_INITIAL_SPANISH: '673565',
    HEART_FAILURE_FOLLOWUP_ENGLISH: '673566',
    HEART_FAILURE_FOLLOWUP_SPANISH: '673567',
    DIABETES_INITIAL_ENGLISH: '673291',
    DIABETES_INITIAL_SPANISH: '673538',
    DIABETES_FOLLOWUP_ENGLISH: '673539',
    DIABETES_FOLLOWUP_SPANISH: '673540',
    DIABETES_DEVICEFLEX_INITIAL_ENGLISH: '673555',
    DIABETES_DEVICEFLEX_INITIAL_SPANISH: '673556',
    DIABETES_DEVICEFLEX_FOLLOWUP_ENGLISH: '673557',
    DIABETES_DEVICEFLEX_FOLLOWUP_SPANISH: '673558',
    PREDIABETES_INITIAL_ENGLISH: '673559',
    PREDIABETES_INITIAL_SPANISH: '673560',
    PREDIABETES_FOLLOWUP_ENGLISH: '673561',
    PREDIABETES_FOLLOWUP_SPANISH: '673562',
    HYPERTENSION_INITIAL_ENGLISH: '673568',
    HYPERTENSION_INITIAL_SPANISH: '673569',
    HYPERTENSION_FOLLOWUP_ENGLISH: '673570',
    HYPERTENSION_FOLLOWUP_SPANISH: '673572',
    WEIGHT_MANAGEMENT_INITIAL_ENGLISH: '673574',
    WEIGHT_MANAGEMENT_INITIAL_SPANISH: '673575',
    WEIGHT_MANAGEMENT_FOLLOWUP_ENGLISH: '673576',
    WEIGHT_MANAGEMENT_FOLLOWUP_SPANISH: '673577',
    ADV_WEIGHT_MANAGEMENT_INITIAL_ENGLISH: '715328',
    ADV_WEIGHT_MANAGEMENT_INITIAL_SPANISH: '715329',
    ADV_WEIGHT_MANAGEMENT_FOLLOWUP_ENGLISH: '715330',
    ADV_WEIGHT_MANAGEMENT_FOLLOWUP_SPANISH: '715331',
    COMPR_WEIGHT_CARE_INITIAL_ENGLISH: '715333',
    COMPR_WEIGHT_CARE_INITIAL_SPANISH: '715334',
    COMPR_WEIGHT_CARE_FOLLOWUP_ENGLISH: '715335',
    COMPR_WEIGHT_CARE_FOLLOWUP_SPANISH: '715336',
    ADOLESCENT_WEIGHT_MANAGEMENT_INITIAL_ENGLISH: '716519',
    ADOLESCENT_WEIGHT_MANAGEMENT_INITIAL_SPANISH: '716520',
    ADOLESCENT_WEIGHT_MANAGEMENT_FOLLOWUP_ENGLISH: '716521',
    ADOLESCENT_WEIGHT_MANAGEMENT_FOLLOWUP_SPANISH: '716522',
};
export const TESTING_SERVICES_IDS = {
    HEART_FAILURE_INITIAL_ENGLISH: '688855',
    HEART_FAILURE_INITIAL_SPANISH: '688856',
    HEART_FAILURE_FOLLOWUP_ENGLISH: '688857',
    HEART_FAILURE_FOLLOWUP_SPANISH: '688858',
    DIABETES_INITIAL_ENGLISH: '688843',
    DIABETES_INITIAL_SPANISH: '688844',
    DIABETES_FOLLOWUP_ENGLISH: '688845',
    DIABETES_FOLLOWUP_SPANISH: '688846',
    DIABETES_DEVICEFLEX_INITIAL_ENGLISH: '688847',
    DIABETES_DEVICEFLEX_INITIAL_SPANISH: '688848',
    DIABETES_DEVICEFLEX_FOLLOWUP_ENGLISH: '688849',
    DIABETES_DEVICEFLEX_FOLLOWUP_SPANISH: '688850',
    PREDIABETES_INITIAL_ENGLISH: '688851',
    PREDIABETES_INITIAL_SPANISH: '688852',
    PREDIABETES_FOLLOWUP_ENGLISH: '688853',
    PREDIABETES_FOLLOWUP_SPANISH: '688854',
    HYPERTENSION_INITIAL_ENGLISH: '688859',
    HYPERTENSION_INITIAL_SPANISH: '688860',
    HYPERTENSION_FOLLOWUP_ENGLISH: '688861',
    HYPERTENSION_FOLLOWUP_SPANISH: '688862',
    WEIGHT_MANAGEMENT_INITIAL_ENGLISH: '688863',
    WEIGHT_MANAGEMENT_INITIAL_SPANISH: '688864',
    WEIGHT_MANAGEMENT_FOLLOWUP_ENGLISH: '688865',
    WEIGHT_MANAGEMENT_FOLLOWUP_SPANISH: '688866',
    ADV_WEIGHT_MANAGEMENT_INITIAL_ENGLISH: '713617',
    ADV_WEIGHT_MANAGEMENT_INITIAL_SPANISH: '713619',
    ADV_WEIGHT_MANAGEMENT_FOLLOWUP_ENGLISH: '713622',
    ADV_WEIGHT_MANAGEMENT_FOLLOWUP_SPANISH: '713624',
    COMPR_WEIGHT_CARE_INITIAL_ENGLISH: '715069',
    COMPR_WEIGHT_CARE_INITIAL_SPANISH: '715070',
    COMPR_WEIGHT_CARE_FOLLOWUP_ENGLISH: '715066',
    COMPR_WEIGHT_CARE_FOLLOWUP_SPANISH: '715067',
    ADOLESCENT_WEIGHT_MANAGEMENT_INITIAL_ENGLISH: '716229',
    ADOLESCENT_WEIGHT_MANAGEMENT_INITIAL_SPANISH: '716230',
    ADOLESCENT_WEIGHT_MANAGEMENT_FOLLOWUP_ENGLISH: '716231',
    ADOLESCENT_WEIGHT_MANAGEMENT_FOLLOWUP_SPANISH: '716232',
};
export const BASIC_AUTH = 'bWVtYmVyUG9ydGFsOjhBM1RHTV5ANlA=';
export const BASIC_AUTH_MOBILE = 'bW9iaWxlSW9zVjE6ODNXN0RBSEI2NQ==';
export const ANY_COACH = 'Any Coach';
export const IS_PROD = process.env.ENVIRONMENT === 'production';
export const FEATURE_FLAG_NAMES = {
    anyCoach: 'enable_any_coach',
    enableNewUi: 'enable_new_ui',
};
export const MH_MIXPANEL_PRODUCT = 'Mental Health';
export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export const WEEKDAY = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];
export const TYPES = {
    INVALID: 0,
    INTRO: 1,
    FOLLOW_UP: 2,
    OTHER: 3,
};
export const METHODS = {
    INVALID: 0,
    PHONE: 1,
    VIDEO: 2,
    OTHER: 3,
};
