import {utcToZonedTime} from 'date-fns-tz';
import {intlFormat, parseISO} from 'date-fns'; // eslint-disable-line no-restricted-imports

export const timeZones = {
    US: [
        {value: 'US/Hawaii', label: 'Hawaii Time'},
        {value: 'US/Alaska', label: 'Alaska Time'},
        {value: 'US/Pacific', label: 'Pacific Time'},
        {value: 'US/Mountain', label: 'Mountain Time'},
        {value: 'US/Central', label: 'Central Time'},
        {value: 'US/Eastern', label: 'Eastern Time'},
    ],
    ES: [
        {value: 'Europe/Madrid', label: 'Central European Time'},
        {value: 'Atlantic/Canary', label: 'Western European Time'},
    ],
};

export const timeZoneObject = {
    US: [
        {
            abbreviation: 'HT',
            timeZoneCode: 'US/Hawaii',
            timeZoneDesc: 'US/Hawaii',
            timeZoneId: 368,
            longName: 'Hawaii-Aleutian Daylight Time',
        },
        {
            abbreviation: 'AK',
            timeZoneCode: 'US/Alaska',
            timeZoneDesc: 'US/Alaska',
            timeZoneId: 362,
            longName: 'Alaska Daylight Time',
        },
        {
            abbreviation: 'PT',
            timeZoneCode: 'US/Pacific',
            timeZoneDesc: 'US/Pacific',
            timeZoneId: 372,
            longName: 'Pacific Daylight Time',
        },
        {
            abbreviation: 'MT',
            timeZoneCode: 'US/Mountain',
            timeZoneDesc: 'US/Mountain',
            timeZoneId: 371,
            longName: 'Mountain Daylight Time',
        },
        {
            abbreviation: 'CT',
            timeZoneCode: 'US/Central',
            timeZoneDesc: 'US/Central',
            timeZoneId: 365,
            longName: 'Central Daylight Time',
        },
        {
            abbreviation: 'ET',
            timeZoneCode: 'US/Eastern',
            timeZoneDesc: 'US/Eastern',
            timeZoneId: 367,
            longName: 'Eastern Daylight Time',
        },
    ],
};

export const defaultTimeZone = (country, locale) => {
    let userTimezone = new Intl.DateTimeFormat(locale, {
        timeZoneName: 'long',
    })
        .formatToParts(new Date())
        .find(part => part.type === 'timeZoneName')
        // value is i.e. "Central Standard Time", and we only want the first word of that string
        .value.split(' ')[0];

    // The method utcToZonedTime doesn't accept the string 'Hawaii-Aleutian' as a timezone value
    if (userTimezone === 'Hawaii-Aleutian') {
        userTimezone = 'Hawaii';
    }

    const detectedTimezone = timeZones[country].find(timezone =>
        timezone.value.includes(userTimezone)
    );

    // Defaulting it to CST since that is the timezone for coaches
    if (!detectedTimezone) {
        return timeZones[country][4];
    }

    return detectedTimezone;
};

export const localizedTime = (time, timezone, locale) => {
    return intlFormat(
        utcToZonedTime(parseISO(time), timezone),
        {
            hour: 'numeric',
            minute: '2-digit',
        },
        {locale}
    );
};

export const getClientTimeZoneObject = (country, selectedTimezone) => {
    return timeZoneObject[country].find(
        timezone => timezone.timeZoneCode === selectedTimezone
    );
};
