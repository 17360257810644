import {useDispatch, useSelector} from 'react-redux';
import {intlFormat, addHours, formatISO} from 'date-fns'; // eslint-disable-line no-restricted-imports
import {useEffect, useState} from 'react';
import DatePicker from '@teladoc/pulse/ui/DatePicker';
import Label from '@teladoc/pulse/ui/Label';
import Select from '@teladoc/pulse/ui/Select';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import useTransLoader from 'i18n/use-trans-loader';
import UserInfoAPI from '../UserInfo/userInfo-api';
import SharedCoaching from '../utils/SharedCoaching';
import {defaultTimeZone} from '../utils/Timezone';
import css from './SchedulingForm.scss';

const SharedCoachingDate = () => {
    const dispatch = useDispatch();
    const {selectedCountry, selectedLang} = useI18nContext();
    const {t} = useTransLoader('schedulingForm');
    const [availableDates, setAvailableDates] = useState([]);
    const [selectedTime, setSelectedTime] = useState('8-11');
    const [selectedDate, setSelectedDate] = useState();
    const {token, uuid} = useSelector(state => state.userData.auth);

    useEffect(() => {
        SharedCoaching.getDatesUnavailable(token).then(data =>
            setAvailableDates(data)
        );
        UserInfoAPI.schedulingData(token, uuid)
            .then(({firstName, lastName}) => {
                dispatch({
                    type: 'SHARED_USER',
                    payload: {
                        subject: `${firstName} ${lastName}`,
                        uuid,
                    },
                });
            })
            .catch(err => {
                dispatch(UserInfoAPI.incorrectToken);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // 'uuid', 'message', 'subject', 'reason', 'preferredDate', 'preferredTimeWindow'
    // {
    //     "uuid": "4076577f-7de4-449b-b1f1-e7017cf3789b",
    //     "message": " ",
    //     "subject": "Peter Wayne",
    //     "reason": 1,
    //     "preferredDate": "2022-02-16T00:00:00",
    //     "preferredTimeWindow": [
    //         "2022-02-16T08:00:00",
    //         "2022-02-16T11:00:00"
    //     ]
    // }

    const getTimeFrames = () => {
        const timeFrames = [
            {start: 8, end: 11},
            {start: 11, end: 14},
            {start: 14, end: 16},
        ];
        const timeFormat = {hour: 'numeric'};
        const locale = {locale: selectedLang};

        return timeFrames.map(({start, end}) => {
            return {
                label: `${intlFormat(
                    new Date(2022, 1, 1, start),
                    timeFormat,
                    locale
                )} - ${intlFormat(
                    new Date(2022, 1, 1, end),
                    timeFormat,
                    locale
                )} ${t(`timeZone.${defaultTimeZone(selectedCountry).label}`)}`,
                value: `${start}-${end}`,
            };
        });
    };

    const updateDate = (date, time) => {
        if (date && time) {
            const timeSplit = time.split('-');
            const start = timeSplit[0];
            const end = timeSplit[1];
            const startDate = formatISO(addHours(new Date(date), start));
            const endDate = formatISO(addHours(new Date(date), end));

            dispatch({
                type: 'SHARED_COACHING_DATE',
                payload: {
                    preferredDate: formatISO(new Date(date)).substr(0, 19),
                    preferredTimeWindow: [
                        startDate.substr(0, startDate.lastIndexOf('-')),
                        endDate.substr(0, endDate.lastIndexOf('-')),
                    ],
                },
            });
        }
    };

    return (
        <div>
            <Select
                id="sharedCoachingTime"
                label={<Label>{t('sessionInfo.time')}</Label>}
                items={getTimeFrames()}
                onChange={timeFrame => {
                    setSelectedTime(timeFrame.value);
                    updateDate(selectedDate, timeFrame.value);
                }}
                required
            />
            <DatePicker
                id="datePicker"
                classNameItem={css.textInput}
                label={<Label>{t('sessionInfo.date')}</Label>}
                calendar={{
                    disablePast: true,
                    disableBefore: availableDates[0],
                    initialMonth: availableDates[0],
                    available: availableDates,
                }}
                name="date"
                onChange={date => {
                    setSelectedDate(date);
                    updateDate(date, selectedTime);
                }}
                required
                disabled={availableDates.length === 0}
            />
        </div>
    );
};

export default SharedCoachingDate;
