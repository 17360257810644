import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import useTransLoader from 'i18n/use-trans-loader';
import css from './Footer.scss';

const FooterPublic = () => {
    const {t} = useTransLoader('footer');
    const {selectedLang} = useI18nContext();
    const REDIRECT_GOTO = process.env.REDIRECT_GOTO;
    let PRIVACY_POLICY_URL = `${REDIRECT_GOTO}/web-privacy-policy`;
    let TERMS_OF_SERVICE_URL = `${REDIRECT_GOTO}/terms-of-service`;
    let PRIVACY_PRACTICES_URL = `${REDIRECT_GOTO}/web-privacy-practice`;

    if (selectedLang !== 'en-US') {
        const locale = `?locale=${selectedLang}`;

        PRIVACY_POLICY_URL += locale;
        TERMS_OF_SERVICE_URL += locale;
        PRIVACY_PRACTICES_URL += locale;
    }

    return (
        <footer className={css.root}>
            <div className={css.container}>
                <p className={css.company}>&copy; Teladoc Health, Inc</p>
                <p className={css.links}>
                    <a
                        target="_blank"
                        className={css.link}
                        rel="noopener noreferrer"
                        href={TERMS_OF_SERVICE_URL}
                    >
                        {t('terms')}
                    </a>
                    <a
                        target="_blank"
                        className={css.link}
                        rel="noopener noreferrer"
                        href={PRIVACY_POLICY_URL}
                    >
                        {t('privacy')}{' '}
                    </a>
                    <a
                        target="_blank"
                        className={css.link}
                        rel="noopener noreferrer"
                        href={PRIVACY_PRACTICES_URL}
                    >
                        {t('privacyPractices')}{' '}
                    </a>
                </p>
                <span>
                    {t('version')} {process.env.VERSION}
                </span>
            </div>
        </footer>
    );
};

export default FooterPublic;
