import APIUtils from '@livongo/utilities/system/api';
import Arg from '@livongo/arg';

const MW = process.env.MIDDLEWARE_URL;
/* appointmentTypeId will be passed in query by myStrength 
 calendarId will only be passed if it's a follow-up appointment,
 so that the member is coached by the same coach they had
 at the initial session */
const query = {
    appointmentType: Arg('appointmentTypeId'),
    calendar: Arg('calendarId'),
};

const Acuity = {
    getAppointmentTypes() {
        return APIUtils.get(`${MW}/v1/coach/appointment/types`, {
            component: 'coach',
        }).then(({data}) => data);
    },
    // @param month: "2021-12" in year-month format
    // @returns dates in YYYY-MM-DD format
    availableCalendarMonth(month) {
        return APIUtils.get(
            `${MW}/v1/welcome/appointment/available/dates?month=${month}`,
            query,
            {'Content-Type': 'application/json'},
            {useMW: true}
        ).then(({data}) => data);
    },
    // @param date = "2021-12-25" in year-month-day format
    // @returns time in YYYY-MM-DD-T-HOUR format
    availableCalendarDate(date) {
        return APIUtils.get(
            `${MW}/v1/welcome/appointment/available/hours?date=${date}`,
            query,
            {'Content-Type': 'application/json'},
            {useMW: true}
        ).then(({data}) => data);
    },
    createAppointment(appointmentData) {
        return APIUtils.post(
            `${MW}/v1/welcome/appointment`,
            {
                ...query,
                ...appointmentData,
            },
            {
                'Content-Type': 'application/json',
                useMW: true,
            }
        ).then(({data}) => data);
    },
};

export default Acuity;
