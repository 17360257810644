import {endOfMonth, format, addMonths, addDays} from 'date-fns'; // eslint-disable-line no-restricted-imports
import APIUtils from '@livongo/utilities/system/api';

const clean = ({data}) => data;

const SharedCoaching = {
    markLaunchpadStatus(token, payload) {
        return APIUtils.patch(
            `${process.env.SERVICE_URL}/service-journey-guidance/v1/users/me/launchpad`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    },
    getLaunchpadStatus(token) {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-journey-guidance/v1/users/me/launchpad`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(data => clean(data));
    },
    coachingInfo(token, id) {
        return APIUtils.get(`/v1/users/${id}/coaching/info`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(data => clean(data));
    },
    getDatesUnavailable(token) {
        return APIUtils.get(
            '/v1/users/me/coaches/scheduling/datesUnavailable',
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then(data => clean(data))
            .then(data => {
                const availableDates = [];
                const unavailableDates = data.map(date =>
                    format(new Date(date), 'MM/dd/yyyy')
                );
                let startDate = new Date();
                const endNextMonth = endOfMonth(addMonths(new Date(), 1));

                while (startDate < endNextMonth) {
                    const date = format(startDate, 'MM/dd/yyyy');

                    if (!unavailableDates.includes(date)) {
                        availableDates.push(date);
                    }
                    startDate = addDays(startDate, 1);
                }

                return availableDates;
            });
    },
    submitSharedCoaching(data, uuid, token) {
        return APIUtils.post(
            `/v1/users/${uuid}/coaches/scheduling/requests`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    },
};

export default SharedCoaching;
