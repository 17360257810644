import PulseLoader from '@teladoc/pulse/ui/Loader';
import css from './Loader.scss';

const Loader = props => {
    return (
        <div>
            <PulseLoader {...props} className={css.root} />
        </div>
    );
};

export default Loader;
