import {render} from 'react-dom';
import {Suspense} from 'react';
import {Provider} from 'react-redux';
import PulseApp, {STATUSES} from '@teladoc/pulse/ui/App';
import APIUtils from '@livongo/utilities/system/api';
import {FeatureFlagProvider} from '@livongo/utilities/system/featureFlag';
import NewRelicUtils from '@livongo/utilities/system/newrelic';
import SentryUtils from '@livongo/utilities/system/sentry';
import Arg from '@livongo/arg';
import createStore from 'store';
import i18n from 'i18n';
import MixpanelUtils from './common/mix-panel';
import App from './App';
import './index.scss';

SentryUtils.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.BUILD_TAG,
    environment: process.env.ENVIRONMENT,
});

APIUtils.init({
    baseUrl: process.env.API_URL,
});

// Update New Relic key values for this repository
const nrObject = {
    ACCOUNT_ID: process.env.ACCOUNT_ID,
    TRUST_KEY: process.env.TRUST_KEY,
    AGENT_ID: process.env.AGENT_ID,
    LICENSE_KEY: process.env.LICENSE_KEY,
    APPLICATION_ID: process.env.APPLICATION_ID,
};

// Initialize New Relic
NewRelicUtils.init({document, nrObject});

MixpanelUtils.init(process.env.MIXPANEL_TOKEN);

const featureFlags = require(`feature-flags/${
    process.env.ENVIRONMENT === 'local'
        ? 'integration'
        : process.env.ENVIRONMENT
}.json`);

render(
    <Suspense>
        <PulseApp
            i18n={i18n({lang: Arg('locale')})}
            render={({data, status}) => {
                switch (status) {
                    case STATUSES.INITIALIZED:
                        return (
                            <Provider store={createStore({})}>
                                <FeatureFlagProvider
                                    featureFlags={featureFlags}
                                >
                                    <App />
                                </FeatureFlagProvider>
                            </Provider>
                        );
                }
            }}
        />
    </Suspense>,
    document.querySelector('.root')
);
