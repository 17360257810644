import APIUtils from '@livongo/utilities/system/api';

const TimeTap = {
    cancelCoachingSession(token, calendarId) {
        const cancellation = {
            reason: '',
            subStatus: 'CANCELLED',
        };

        return APIUtils.put(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/appointments/${calendarId}/cancel`,
            cancellation,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data);
    },
    cancelCoachingSessionNonProxyCcmOnly(token, calendarId) {
        const cancellation = {
            reason: '',
            subStatus: 'CANCELLED',
        };

        return APIUtils.put(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/me/appointments/${calendarId}/cancel`,
            cancellation,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data);
    },
    getCoachingSession(token, calendarId) {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/appointments/${calendarId}`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data);
    },
    getApiSessionToken() {
        try {
            // call BE to get api token
            throw new Error('BE service not exist yet');
        } catch {
            try {
                const mw = process.env.TIME_TAP_BACKUP;

                return APIUtils.get(`${mw}/v1/api-session-token`)
                    .then(({data}) => data.sessionToken)
                    .catch(ex => {
                        return this.getSessionToken();
                    });
            } catch {
                return this.getSessionToken();
            }
        }
    },
    getClientObject(token, email) {
        return APIUtils.post(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/clients/filter`,
            email,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'text/plain',
                },
            }
        ).then(({data}) => data);
    },
    createNewClientObject(token, clientData) {
        return APIUtils.post(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/clients`,
            clientData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data);
    },
    getLocationObject(token, locationId) {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/locations/${locationId}`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data);
    },
    getReasonObject(token, serviceId) {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/services/${serviceId}`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data);
    },
    getCommentFieldObject(token, schedulerPreferenceFieldDefnId) {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/schedulerField/${schedulerPreferenceFieldDefnId}`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data);
    },
    getSchedulerFieldsObjects(token) {
        // "APPT" will return the fields assigned to the appointment detail, which are
        // "Comments", "Do you use assistive devices?", and "Do you have a video camera?"
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/schedulerFieldList/mode/APPT`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data);
    },
    getStaff(token, serviceId, locationId) {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/staff?acceptAppointments=true&locationId=${locationId}&reasonId=${serviceId}`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data);
    },
    getLastSessionData(token, clientId) {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/appointments/report?clientId=${clientId}&statusList=COMPLETED&order_field=startDate&order_mode=desc`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data[0]);
    },
    getCoachAvailableDates(
        token,
        year,
        month,
        staffId,
        serviceId,
        duration,
        locationId
    ) {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/availability/${year}/${month}/${staffId}/${locationId}/${serviceId}?duration=${duration}`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data.openDays);
    },
    getCoachAvailableTimes(
        token,
        year,
        month,
        day,
        staffId,
        serviceId,
        duration,
        locationId
    ) {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/availability/${year}/${month}/${day}/${staffId}/${locationId}/${serviceId}?duration=${duration}`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data);
    },
    getAllCoachesAvailableDates(
        token,
        serviceId,
        startDate,
        endDate,
        locationId
    ) {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/availability/location/${locationId}/service/${serviceId}?startDate=${startDate}&endDate=${endDate}`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data);
    },
    getCoachAppointmentCount(token, staffId) {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/appointmentList/reportCount?staffIdList=${staffId}`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => {
            return {coachId: staffId, count: data?.count};
        });
    },
    createAppointment(token, appointmentData) {
        return APIUtils.post(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/proxy/me/appointments`,
            appointmentData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data);
    },
    createAppointmentNonProxyCcmOnly(token, appointmentData) {
        return APIUtils.post(
            `${process.env.SERVICE_URL}/service-coaching/v1/timetap/me/appointments`,
            appointmentData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => data);
    },
    getOtherCoachesAvailableDatesMinusTarget(availableDatesResponse, target) {
        const ary = availableDatesResponse;

        for (let i = 0; i < ary.length; i++) {
            const timeSlots = ary[i]?.timeSlots;

            if (timeSlots?.length === 1) {
                // Edge case
                const units = timeSlots[0].units;

                if (
                    units?.length === 1 &&
                    units[0].professionalId?.toString() === target?.toString()
                ) {
                    ary[i].ignoreDate = true;
                }
            }
            ary[i].tsTemp = [];

            for (let j = 0; j < timeSlots.length; j++) {
                const units = timeSlots[j].units;

                if (
                    units?.length === 1 &&
                    units[0].professionalId?.toString() !== target?.toString()
                ) {
                    ary[i].tsTemp.push(timeSlots[j]);
                } else if (units?.length > 1) {
                    ary[i].tsTemp.push(timeSlots[j]);
                }
            }
        }

        return ary?.filter(t => !t.ignoreDate);
    },
};

export default TimeTap;
