import {useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import {cnb} from 'cnbuilder';
import addMinutes from 'date-fns/addMinutes';
import intlFormat from 'date-fns/intlFormat';
import parseISO from 'date-fns/parseISO';
import {format, utcToZonedTime} from 'date-fns-tz';
import isEmpty from 'lodash/isEmpty';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import Button from '@teladoc/pulse/ui/Button';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import Arg from '@livongo/arg';
import useTransLoader from 'i18n/use-trans-loader';
import {goToMyStrength} from '../utils/myStrength';
import {getClientTimeZoneObject} from '../utils/Timezone';
import css from './ConfirmationPage.scss';

const ConfirmationPage = () => {
    const {t} = useTransLoader('confirmation');
    const navigate = useNavigate();
    const location = useLocation();
    const {selectedLang, selectedCountry} = useI18nContext();
    const localeFormat = {locale: selectedLang};
    const {
        type,
        datetime,
        startDate,
        endDate,
        duration,
        timezone,
        calendar,
        coach,
    } = useSelector(state => state.scheduler.appointmentData);
    const contentRef = useRef();
    const hasSessionInPastYear = useSelector(state => state.userData.userData);
    const {sharedCoaching} = useSelector(state => state.scheduler);
    const isSharedCoaching = !isEmpty(sharedCoaching);
    const isMentalHealth = Arg('referrer')?.includes('mystrength');
    const isWebSDK = Arg('oneAppSdk');
    const rootClass = cnb(css.root, {
        [css.webSdk]: isWebSDK,
    });
    const secondaryTitleClass = cnb(css.secondaryTitle, {
        [css.webSdk]: isWebSDK,
    });
    const eventName = isSharedCoaching
        ? 'Shared Coaching'
        : isMentalHealth
        ? type.replace('[TEST]', '').replace('(INT)', '').trim()
        : type;
    const utcStartDate = isSharedCoaching
        ? new Date(sharedCoaching?.preferredTimeWindow[0])
        : isMentalHealth
        ? parseISO(datetime)
        : startDate;
    const utcEndDate = isSharedCoaching
        ? new Date(sharedCoaching?.preferredTimeWindow[1])
        : isMentalHealth
        ? addMinutes(utcStartDate, parseInt(duration || 0, 10))
        : endDate;
    const localStartDate = utcStartDate
        ? utcToZonedTime(utcStartDate, timezone || 'America/Chicago')
        : null;
    const localEndDate = utcStartDate
        ? utcToZonedTime(utcEndDate, timezone || 'America/Chicago')
        : null;
    const date = intlFormat(
        localStartDate,
        {
            month: 'long',
            day: 'numeric',
        },
        localeFormat
    );
    const time = intlFormat(
        localStartDate,
        {
            hour: 'numeric',
            minute: 'numeric',
        },
        localeFormat
    );
    const timezoneObject = getClientTimeZoneObject(selectedCountry, timezone);
    const enableNewUi = Arg('enableNewUi') === true;

    useEffect(() => {
        if (enableNewUi) {
            navigate(`/v2/confirmation?${location?.search}`);
        }
    }, [location, navigate, enableNewUi]);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (isWebSDK) {
            const sendHeightToParent = () => {
                const message = {height: document.body.scrollHeight};

                window.top.postMessage(message, '*');
            };

            if (window.ResizeObserver) {
                const resizeObserver = new ResizeObserver(entries =>
                    entries.forEach(entry => sendHeightToParent())
                );

                resizeObserver.observe(document.body);
            }
        }
    }, [isWebSDK]);

    const getConfirmationInfo = () => {
        return !isEmpty(sharedCoaching)
            ? t('sharedSessionInfo', {
                  date: intlFormat(
                      new Date(sharedCoaching.preferredDate),
                      {
                          month: 'long',
                          day: 'numeric',
                      },
                      localeFormat
                  ),
                  startTime: intlFormat(
                      new Date(sharedCoaching.preferredTimeWindow[0]),
                      {
                          hour: 'numeric',
                          minute: 'numeric',
                      },
                      localeFormat
                  ),
                  endTime: intlFormat(
                      new Date(sharedCoaching.preferredTimeWindow[1]),
                      {
                          hour: 'numeric',
                          minute: 'numeric',
                      },
                      localeFormat
                  ),
              })
            : isMentalHealth
            ? t('mhSessionInfo', {
                  calendar,
                  date,
                  time,
              })
            : !hasSessionInPastYear
            ? // We don't show the coach's name for an initial appointment
              t('intialSessionInfo', {
                  type,
                  date,
                  time,
                  timezone: timezoneObject?.abbreviation,
              })
            : t('followupSessionInfo', {
                  type,
                  coach,
                  date,
                  time,
                  timezone: timezoneObject?.abbreviation,
              });
    };

    useEffect(() => {
        contentRef?.current?.focus();
    }, []);

    return (
        <div className={rootClass}>
            <div
                id="main"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                ref={contentRef}
                tabIndex={-1}
                aria-label={`${!isWebSDK ? t('title') : ''} ${t(
                    'sessionScheduled'
                )} ${getConfirmationInfo()} ${t('nextSteps')}`}
            >
                {!isWebSDK && (
                    <h1 aria-hidden="true" className={css.title}>
                        {t('title')}
                    </h1>
                )}
                <h2 aria-hidden="true" className={secondaryTitleClass}>
                    {t('sessionScheduled')}
                </h2>
                <h3 aria-hidden="true" className={css.sectionTitle}>
                    {getConfirmationInfo()}
                </h3>
                <p aria-hidden="true">{t('nextSteps')}</p>
                <HorizontalRule spaceTop={24} spaceBottom={24} />
            </div>
            <div>
                <p>{t('calendar')}</p>
                <div className={css.calendarButtons}>
                    <Button
                        type="submit"
                        variant="secondary"
                        className={css.appleButton}
                        role="link"
                        onClick={() => {
                            window.open(
                                `${
                                    process.env.MIDDLEWARE_URL
                                }/v1/coach/ical/event/?end=${format(
                                    utcToZonedTime(utcEndDate, 'UTC'),
                                    'yyyyMMddHHmmss'
                                )}&start=${format(
                                    utcToZonedTime(utcStartDate, 'UTC'),
                                    'yyyyMMddHHmmss'
                                )}&summary=${encodeURI(eventName)}`
                            );
                        }}
                    >
                        iCAL/Outlook
                    </Button>
                    <Button
                        type="submit"
                        variant="secondary"
                        className={css.googleButton}
                        role="link"
                        onClick={() => {
                            window.open(
                                `https://calendar.google.com/calendar/u/0/r/eventedit?text=${eventName}&dates=${format(
                                    localStartDate,
                                    'yyyyLLdd'
                                )}T${format(localStartDate, 'HHmmss')}/${format(
                                    localEndDate,
                                    'yyyyLLdd'
                                )}T${format(localEndDate, 'HHmmss')}`
                            );
                        }}
                    >
                        Google
                    </Button>
                </div>
                {isMentalHealth && (
                    <Button
                        type="submit"
                        variant="primary"
                        className={css.doneButton}
                        role="link"
                        onClick={goToMyStrength}
                    >
                        {t('done')}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ConfirmationPage;
