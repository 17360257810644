import {useSelector} from 'react-redux';
import Arg from '@livongo/arg';
import Login from '../Login/Login';
import SchedulingForm from '../SchedulingForm/SchedulingForm';
import SchedulingFormMyStrength from '../SchedulingFormMyStrength/SchedulingFormMyStrength';
import SchedulingFormMentalHealth from '../SchedulingFormMentalHealth/SchedulingFormMentalHealth';
import SchedulingFormV2 from '../SchedulingForm/SchedulingFormV2';

const referrer = Arg('referrer') || 'livongo-web';
const enableNewUi = Arg('enableNewUi') === true;
const referringProduct = referrer ? referrer.split('-')[0] : null;

const SchedulingPage = () => {
    const {token} = useSelector(state => state.userData.auth);

    return (
        <>
            {referringProduct === 'livongo' &&
                (token ? (
                    enableNewUi ? (
                        <SchedulingFormV2 />
                    ) : (
                        <SchedulingForm />
                    )
                ) : (
                    <Login />
                ))}
            {referringProduct === 'mystrength' && <SchedulingFormMyStrength />}
            {referringProduct === 'mentalhealth' && (
                <SchedulingFormMentalHealth />
            )}
        </>
    );
};

export default SchedulingPage;
