import {useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import Button from '@teladoc/pulse/ui/Button';
import FormGroupError from '@teladoc/pulse/ui/FormGroupError';
import Form from '@teladoc/pulse/ui/Form';
import Label from '@teladoc/pulse/ui/Label';
import PasswordInput from '@teladoc/pulse/ui/PasswordInput';
import TextInput from '@teladoc/pulse/ui/TextInput';
import APIUtils from '@livongo/utilities/system/api';
import StorageUtils from '@livongo/utilities/system/storage';
import useTransLoader from 'i18n/use-trans-loader';
import css from '../SchedulingForm/SchedulingForm.scss';

const Login = () => {
    const {t} = useTransLoader('app');
    const dispatch = useDispatch();
    const formRef = useRef();
    const basicAuthConfig = {
        headers: {
            Authorization: `Basic bWVtYmVyUG9ydGFsOjhBM1RHTV5ANlA=`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const [loginError, setLoginError] = useState('');

    const signIn = () => {
        const form = formRef.current;
        /* eslint-disable camelcase */
        const data = {
            grant_type: 'password',
            ...form.submit(),
        };

        APIUtils.post('v1/users/me/auth', data, basicAuthConfig)
            .then(({data: {access_token: accessToken}}) => {
                if (accessToken) {
                    dispatch({
                        type: 'AUTH',
                        payload: {
                            token: accessToken,
                            uuid: JSON.parse(
                                atob(accessToken.split('.')[1])
                            ).sub.replace('user/liv/', ''),
                        },
                    });

                    StorageUtils.set({
                        key: 'access_token',
                        value: accessToken,
                        type: 'cookie',
                        useNative: true,
                        cookieOptions: {
                            domain: 'livongo.com',
                            sameSite: 'none',
                            secure: true,
                        },
                        stringify: false,
                    });
                }
            })
            .catch(({data: {error_description: errorDescription}}) =>
                setLoginError(errorDescription)
            );
    };

    return (
        <div>
            <div className={css.root}>
                <Form ref={formRef} id="loginForm">
                    <h2>{t('login.heading')}</h2>
                    <TextInput
                        id="username"
                        name="username"
                        type="email"
                        required
                        label={<Label>{t('login.email')}</Label>}
                    />
                    <PasswordInput
                        id="password"
                        name="password"
                        required
                        label={<Label>{t('login.password')}</Label>}
                    />
                    <div>
                        <a
                            href={`${process.env.MEMBER_PORTAL_URL}/forgot-password`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('login.forgot')}
                        </a>
                    </div>
                    {loginError && (
                        <FormGroupError
                            title="Uh oh, there was an error"
                            errors={{
                                message: loginError,
                            }}
                        />
                    )}
                    <Button
                        type="submit"
                        variant="primary"
                        className={css.loginButton}
                        onClick={signIn}
                    >
                        {t('login.submit')}
                    </Button>
                </Form>
            </div>
        </div>
    );
};

export default Login;
