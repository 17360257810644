import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import useTransLoader from 'i18n/use-trans-loader';
import css from './ConfirmationDetails.scss';

const ConfirmationDetails = () => {
    const {t} = useTransLoader('confirmation');

    return (
        <div>
            <div>
                <h2 className={css.fullSessionDetails}>
                    {t('beforeTheSession.title')}
                </h2>
            </div>
            <div>
                <ul>
                    <li>{t(`beforeTheSession.comment1`)}</li>
                    <li>{t(`beforeTheSession.comment2`)}</li>
                    <li>{t(`beforeTheSession.comment3`)}</li>
                </ul>
            </div>
            <HorizontalRule spaceTop={24} spaceBottom={24} />
            <div>
                <div>
                    <h2 className={css.fullSessionDetails}>
                        {t('duringTheSession.title')}
                    </h2>
                </div>
                <ul>
                    <li>{t(`duringTheSession.comment1`)}</li>
                    <li>{t(`duringTheSession.comment2`)}</li>
                    <li>{t(`duringTheSession.comment3`)}</li>
                </ul>
            </div>
            <HorizontalRule spaceTop={24} spaceBottom={24} />
            <div>
                <div>
                    <h2 className={css.fullSessionDetails}>
                        {t('afterTheSession.title')}
                    </h2>
                </div>
                <ul>
                    <li>{t(`afterTheSession.comment1`)}</li>
                    <li>{t(`afterTheSession.comment2`)}</li>
                    <li>{t(`afterTheSession.comment3`)}</li>
                </ul>
            </div>
        </div>
    );
};

export default ConfirmationDetails;
