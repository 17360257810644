export const BUILD_TAG_KEY = 'coaching-build-tag';
export const BUILD_TAG = process.env.BUILD_TAG;

export default function init({lang}) {
    const appNamespace = 'app';
    const commonNamespace = 'common';
    const schedulingForm = 'schedulingForm';
    const userInfo = 'userInfo';
    const confirmation = 'confirmation';
    const error = 'error';
    /* See default config from @livongo/fe-ui in the link below
     * https://github.com/livongo/fe-ui/blob/master/src/Context/i18n/init.js#L53
     */

    return {
        /*
         * Load messages ahead of time since they used in the flyout
         *  and, flyout has to deal with time delay.
         */
        ns: [
            appNamespace,
            commonNamespace,
            schedulingForm,
            userInfo,
            confirmation,
            error,
        ],
        defaultNS: appNamespace,
        fallbackNS: commonNamespace,
        lng: lang,
        debug: true,
        resources: {
            'en-US': {
                app: require('./locales/en-US/app.json'),
                common: require('./locales/en-US/common.json'),
                footer: require('./locales/en-US/footer.json'),
                schedulingForm: require('./locales/en-US/schedulingForm.json'),
                userInfo: require('./locales/en-US/userInfo.json'),
                confirmation: require('./locales/en-US/confirmation.json'),
                error: require('./locales/en-US/error.json'),
            },
            'es-US': {
                app: require('./locales/es-US/app.json'),
                common: require('./locales/es-US/common.json'),
                footer: require('./locales/es-US/footer.json'),
                schedulingForm: require('./locales/es-US/schedulingForm.json'),
                userInfo: require('./locales/es-US/userInfo.json'),
                confirmation: require('./locales/es-US/confirmation.json'),
                error: require('./locales/es-US/error.json'),
            },
        },
    };
}
