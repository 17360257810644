import APIUtils from '@livongo/utilities/system/api';
import {BASIC_AUTH, BASIC_AUTH_MOBILE} from '../config';

const UserInfoAPI = {
    schedulingData(token, uuid) {
        return APIUtils.get(
            `/v1/users/${uuid}/info/coaching/scheduling`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then(({data}) => {
            return {...data, uuid};
        });
    },
    getAuthToken(uuid) {
        return APIUtils.post(
            `${process.env.MIDDLEWARE_URL}/v1/partner/auth/user`,
            {
                name: uuid,
                scope: 'ReadUser WriteUser',
            }
        );
    },
    getInternalUserId(token, uuid) {
        return APIUtils.get(`/v1/users/${uuid}/account`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(({data}) => data);
    },
    incorrectToken: {
        type: 'AUTH',
        payload: {
            uuid: '',
            token: '',
        },
    },
    getOneAppAccessToken(code, isWeb) {
        return APIUtils.post(
            `${process.env.SERVICE_URL}/service-education/v1/users/me/education/auth`,
            {
                code,
                grant_type: 'authorization_code', // eslint-disable-line camelcase
            },
            {
                headers: {
                    Authorization: `Basic ${
                        isWeb ? BASIC_AUTH : BASIC_AUTH_MOBILE
                    }`,
                },
            }
        ).then(({data}) => data?.access_token);
    },
    getAccessToken(code, isWeb) {
        return APIUtils.post(
            '/v1/users/me/auth',
            {
                code,
                grant_type: 'authorization_code', // eslint-disable-line camelcase
            },
            {
                headers: {
                    Authorization: `Basic ${
                        isWeb ? BASIC_AUTH : BASIC_AUTH_MOBILE
                    }`,
                },
            }
        ).then(({data}) => data?.access_token);
    },
};

export default UserInfoAPI;
