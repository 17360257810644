import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {add, differenceInMonths, parse, parseISO} from 'date-fns'; // eslint-disable-line no-restricted-imports
import {format} from 'date-fns-tz';
import {useNavigate} from 'react-router-dom';
import {cnb} from 'cnbuilder';
import IconArrowLeftDefault from '@teladoc/pulse/icons/arrow-left-default.svg';
import Button from '@teladoc/pulse/ui/Button';
import DatePicker from '@teladoc/pulse/ui/DatePicker';
import Form from '@teladoc/pulse/ui/Form';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import Label from '@teladoc/pulse/ui/Label';
import TextArea from '@teladoc/pulse/ui/TextArea';
import Select from '@teladoc/pulse/ui/Select';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import Arg from '@livongo/arg';
import useTransLoader from 'i18n/use-trans-loader';
import AcuityError from '../ErrorMessages/AcuityError';
import Acuity from '../utils/Acuity';
import {goToMyStrength} from '../utils/myStrength';
import {timeZones, defaultTimeZone, localizedTime} from '../utils/Timezone';
import UserInfo from '../UserInfo/UserInfo';
import UserInfoAPI from '../UserInfo/userInfo-api';
import css from './SchedulingFormMyStrength.scss';
import '../index.scss';

const SchedulingForm = () => {
    const {t} = useTransLoader('schedulingForm');
    const {selectedCountry, selectedLang} = useI18nContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {userData, error} = useSelector(state => state.userData);
    const {token, uuid} = useSelector(state => state.userData.auth);
    const [assistiveDevice, setAsstistiveDevice] = useState('No');
    const [videoCamera, setVideoCamera] = useState('Yes');
    const [availableTimes, setAvailableTimes] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [availableDates, setAvailableDates] = useState([]);
    const [APIError, setAPIError] = useState();
    const [selectedDate, setSelectedDate] = useState();
    const [selectedTimezone, setSelectedTimezone] = useState(
        defaultTimeZone(selectedCountry)
    );
    const [initialMonth, setInitialMonth] = useState();
    const [notes, setNotes] = useState('');
    const [expirationTime, setExpirationTime] = useState();
    const [internalUserId, setInternalUserId] = useState(null);
    const isWebSDK = Arg('oneAppSdk');
    const rootClass = cnb(css.root, {
        [css.webSdk]: isWebSDK,
    });
    const subheadingClass = cnb(css.subheading, {
        [css.webSdk]: isWebSDK,
    });
    const buttonClass = cnb(css.confirmButton, {
        [css.webSdk]: isWebSDK,
    });

    function timezoneChange(timezone) {
        setAvailableTimes(
            availableTimes.map(date => {
                return {
                    label: localizedTime(
                        date.value,
                        timezone.value,
                        selectedLang
                    ),
                    value: date.value,
                };
            })
        );
        setSelectedTimezone(timezone);
    }

    function getAvailableDates() {
        const requests = [];
        let monthsAhead = 6;
        let expiration;

        if (Arg('expirationDate')) {
            expiration = parseISO(Arg('expirationDate'));
            setExpirationTime(expiration);
            monthsAhead = differenceInMonths(expiration, new Date());
        }

        for (let i = 0; i <= monthsAhead; i++) {
            requests.push(
                Acuity.availableCalendarMonth(
                    format(add(new Date(), {months: i}), 'yyyy-MM')
                )
            );
        }
        Promise.all(requests)
            .then(data => {
                const availableDatesResponse = data.flat();
                const parsedDates = availableDatesResponse.map(({date}) => {
                    return parse(date, 'yyyy-MM-dd', new Date());
                });

                const formattedDates = parsedDates
                    .filter(
                        date =>
                            (expiration && new Date(date) <= expiration) ||
                            !expiration
                    )
                    .map(date => format(date, 'MM/dd/yyyy'));

                setAvailableDates(formattedDates);

                if (parsedDates.length) {
                    setInitialMonth(format(parsedDates[0], 'MM/dd/yyyy'));
                }
            })
            .catch(err => {
                setAPIError(err);
            });
    }

    function scheduleCoachAppointment() {
        const {firstName, lastName, phones, email} = userData;
        const appointmentData = {
            component: 'coach-mystrength',
            datetime: selectedDate,
            email,
            firstName,
            lastName,
            notes,
            phone: phones[0].number,
            assistiveDevice,
            videoCamera,
            timezone: selectedTimezone.value,
            userUUID: !internalUserId ? uuid : internalUserId,
        };

        Acuity.createAppointment(appointmentData)
            .then(data => {
                dispatch({
                    type: 'APPOINTMENT_DETAILS',
                    payload: {
                        calendar: data?.calendar,
                        datetime: data?.datetime,
                        timezone: data?.timezone,
                        type: data?.type,
                        duration: data?.duration,
                    },
                });

                navigate('/confirmation');
            })
            .catch(err => {
                setAPIError(err);
            });
    }

    useEffect(() => {
        getAvailableDates();
        setAPIError(error);
    }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isWebSDK) {
            if (uuid !== '') {
                UserInfoAPI.getInternalUserId(token, uuid)
                    .then(data => setInternalUserId(data.uuid))
                    .catch(err => {
                        setAPIError(err);
                    });
            }
        }
    }, [isWebSDK, uuid, token, error]);

    return (
        <div className={rootClass}>
            <AcuityError isOpen={APIError} toggleModal={setAPIError} />
            {!isWebSDK && (
                <h1 className={css.title}>{t('confirmation:title')}</h1>
            )}
            <h2 className={subheadingClass}>{t('title')}</h2>
            <Form onSubmit={scheduleCoachAppointment}>
                <div>
                    <h3 className={css.sectionTitle}>{t('contactInfo')}</h3>
                    <UserInfo />
                    <HorizontalRule spaceTop={24} spaceBottom={24} />
                </div>
                <div>
                    <h3 className={css.sectionTitle}>
                        {t('myStrength.details')}
                    </h3>
                    <Select
                        id="haveAssistiveDevice"
                        label={<Label>{t('myStrength.device')}</Label>}
                        items={[
                            {
                                label: t('myStrength.yes'),
                                value: t('myStrength.yes'),
                            },
                            {
                                label: t('myStrength.no'),
                                value: t('myStrength.no'),
                            },
                        ]}
                        onChange={({value}) => setAsstistiveDevice(value)}
                    />
                    <Select
                        id="haveCamera"
                        label={<Label>{t('myStrength.video')}</Label>}
                        items={[
                            {
                                label: t('myStrength.yes'),
                                value: t('myStrength.yes'),
                            },
                            {
                                label: t('myStrength.no'),
                                value: t('myStrength.no'),
                            },
                        ]}
                        onChange={({value}) => setVideoCamera(value)}
                    />
                    <HorizontalRule spaceTop={24} spaceBottom={24} />
                </div>
                <div>
                    <h3 className={css.sectionTitle}>
                        {t('sessionInfo.dates')}
                    </h3>
                    <Select
                        id="timeZone"
                        label={<Label>{t('sessionInfo.timezone')}</Label>}
                        placeholder={t('sessionInfo.timezonePlaceholder')}
                        defaultValue={t(selectedTimezone?.value)}
                        items={timeZones[selectedCountry].map(
                            ({value, label}) => ({
                                value,
                                label: t(`timeZone.${label}`),
                            })
                        )}
                        onChange={tz => timezoneChange(tz)}
                        required
                    />
                    <DatePicker
                        id="date"
                        classNameItem={css.dateInput}
                        calendar={{
                            disablePast: true,
                            disableBefore: initialMonth,
                            initialMonth,
                            available: availableDates,
                        }}
                        disabled={!initialMonth}
                        label={<Label> {t('sessionInfo.date')}</Label>}
                        onChange={date => {
                            const newSelectedDate = format(
                                new Date(date),
                                'yyyy-MM-dd'
                            );

                            setSelectedDate();
                            setAvailableTimes([]);
                            Acuity.availableCalendarDate(newSelectedDate).then(
                                data => {
                                    if (data.length) {
                                        setSelectedDate(data[0].time);
                                    }

                                    setAvailableTimes(
                                        data
                                            .filter(currentDate => {
                                                return expirationTime
                                                    ? parseISO(
                                                          currentDate.time
                                                      ) < expirationTime
                                                    : true;
                                            })
                                            .map(currentDate => {
                                                return {
                                                    label: localizedTime(
                                                        currentDate.time,
                                                        selectedTimezone.value,
                                                        selectedLang
                                                    ),

                                                    value: currentDate.time,
                                                };
                                            })
                                    );
                                }
                            );
                        }}
                        placeholder={t('sessionInfo.datePlaceholder')}
                        required
                    />
                    <Select
                        id="time"
                        label={<Label> {t('sessionInfo.time')}</Label>}
                        items={availableTimes}
                        onChange={date => setSelectedDate(date.value)}
                        placeholder={t('sessionInfo.timePlaceholder')}
                        required
                    />
                    <HorizontalRule spaceTop={24} spaceBottom={24} />
                </div>
                <TextArea
                    id="note"
                    name="note"
                    label={
                        <Label className={css.sectionTitle}>
                            {t('sessionInfo.notes')}
                        </Label>
                    }
                    placeholder={t('sessionInfo.notesPlaceholder')}
                    onChange={e => setNotes(e.target.value)}
                />
                {!isWebSDK && (
                    <Button
                        variant="secondary"
                        className={css.backButton}
                        onClick={goToMyStrength}
                    >
                        <IconArrowLeftDefault />
                    </Button>
                )}
                <Button type="submit" variant="primary" className={buttonClass}>
                    {t('sessionInfo.submit')}
                </Button>
            </Form>
        </div>
    );
};

export default SchedulingForm;
