import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import intlFormat from 'date-fns/intlFormat';
import {format, utcToZonedTime} from 'date-fns-tz';
import {cnb} from 'cnbuilder';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import IconCalendarPlusDefault from '@teladoc/pulse/icons/calendar-plus-default.svg';
import Button from '@teladoc/pulse/ui/Button';
import Image from '@teladoc/pulse/ui/Image';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import DOMUtils from '@livongo/utilities/system/dom';
import Arg from '@livongo/arg';
import useTransLoader from 'i18n/use-trans-loader';
import {goToMyStrength} from '../utils/myStrength';
import {getClientTimeZoneObject} from '../utils/Timezone';
import css from './ConfirmationPageMentalHealth.scss';

const ConfirmationPageMentalHealth = () => {
    const {t} = useTransLoader('confirmation');
    const {selectedLang, selectedCountry} = useI18nContext();
    const localeFormat = {locale: selectedLang};
    const {type, startDate, endDate, timezone, coach, isNewCoach} = useSelector(
        state => state.scheduler.appointmentData
    );
    const eventName = type;
    const utcStartDate = startDate;
    const utcEndDate = endDate;
    const localStartDate = utcToZonedTime(
        utcStartDate,
        timezone || 'America/Chicago'
    );
    const localEndDate = utcToZonedTime(
        utcEndDate,
        timezone || 'America/Chicago'
    );
    const date = intlFormat(
        localStartDate,
        {
            month: 'long',
            day: 'numeric',
        },
        localeFormat
    );
    const time = intlFormat(
        localStartDate,
        {
            hour: 'numeric',
            minute: 'numeric',
        },
        localeFormat
    );
    const timezoneObject = getClientTimeZoneObject(selectedCountry, timezone);
    const isOneApp = Arg('oneAppSdk');
    const rootClass = cnb(css.root, {
        [css.oneapp]: isOneApp,
    });
    const screenWidth = DOMUtils.width('body');
    const isMobileView = screenWidth <= '767';

    useEffect(() => {
        window.scrollTo(0, 0);

        const sendHeightToParent = () => {
            const message = {height: document.body.scrollHeight};

            window.top.postMessage(message, '*');
        };

        if (window.ResizeObserver) {
            const resizeObserver = new ResizeObserver(entries =>
                entries.forEach(entry => sendHeightToParent())
            );

            resizeObserver.observe(document.body);
        }
    }, []);

    const getConfirmationInfo = () => {
        return t('mentalHealthSessionInfo', {
            date,
            time,
            timezone: timezoneObject?.abbreviation,
            coach,
        });
    };

    const goToHomePage = () => {
        if (isOneApp) {
            const oneAppReturnUrl = 'url:/screens/my_strength/mh_home';
            const message = {redirect: oneAppReturnUrl};

            window.top.postMessage(message, '*');
            window.location.href = oneAppReturnUrl;
        } else {
            window.open(
                `${process.env.MYSTRENGTH_URL}${decodeURIComponent(
                    Arg('returnPath')
                )}`,
                '_self'
            );
        }

        return;
    };

    return (
        <div className={rootClass}>
            <div>
                <h1 className={css.title}>{t('sessionScheduled')}</h1>
                <h2 className={css.sessionInfo}>{getConfirmationInfo()}</h2>
                <p>{t('nextSteps')}</p>
                {!isNewCoach && !isMobileView && (
                    <HorizontalRule spaceTop={24} />
                )}
                {isNewCoach && (
                    <div className={css.card}>
                        <p className={css.cardHeading}>{t('cardHeading')}</p>
                        <h2 className={css.cardCoachName}>{coach}</h2>
                        <div className={css.cardImageContainer}>
                            <Image
                                cloudinaryImageId="coach/coach_portrait_dhvyvv"
                                width={165}
                                height={165}
                                alt=""
                            />
                        </div>
                        <p>{t('cardDescription')}</p>
                        <Button
                            variant="primary"
                            role="link"
                            onClick={goToMyStrength}
                        >
                            {t('cardButton')}
                        </Button>
                    </div>
                )}
            </div>
            <div className={css.buttonsContainer}>
                <div className={css.calendarContainer}>
                    <p>{t('calendar')}</p>
                    <div className={css.calendarButtons}>
                        <Button
                            role="link"
                            onClick={() => {
                                window.open(
                                    `${
                                        process.env.MIDDLEWARE_URL
                                    }/v1/coach/ical/event/?end=${format(
                                        utcToZonedTime(utcEndDate, 'UTC'),
                                        'yyyyMMddHHmmss'
                                    )}&start=${format(
                                        utcToZonedTime(utcStartDate, 'UTC'),
                                        'yyyyMMddHHmmss'
                                    )}&summary=${encodeURI(eventName)}`
                                );
                            }}
                        >
                            <IconCalendarPlusDefault className={css.icon} />
                            iCAL/Outlook
                        </Button>
                        <Button
                            className={css.googleButton}
                            role="link"
                            onClick={() => {
                                window.open(
                                    `https://calendar.google.com/calendar/u/0/r/eventedit?text=${eventName}&dates=${format(
                                        localStartDate,
                                        'yyyyLLdd'
                                    )}T${format(
                                        localStartDate,
                                        'HHmmss'
                                    )}/${format(
                                        localEndDate,
                                        'yyyyLLdd'
                                    )}T${format(localEndDate, 'HHmmss')}`
                                );
                            }}
                        >
                            <IconCalendarPlusDefault className={css.icon} />
                            Google Calendar
                        </Button>
                    </div>
                </div>
                {isMobileView && (
                    <HorizontalRule spaceTop={24} spaceBottom={24} />
                )}
                <div>
                    <Button
                        variant="secondary"
                        className={css.goToHomeButton}
                        role="link"
                        onClick={goToHomePage}
                    >
                        {t('goHomepage')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPageMentalHealth;
