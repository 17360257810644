import Arg from '@livongo/arg';

const mockAcuityData = {
    id: 752231102,
    firstName: 'Nicolas',
    lastName: 'Vrhovski',
    phone: '4125555555',
    email: 'nicolas.vrhovski+536@tudip.com',
    date: 'February 15, 2022',
    time: '2:45pm',
    endTime: '3:30pm',
    dateCreated: 'January 20, 2022',
    datetimeCreated: '2022-01-20T16:26:16-0600',
    datetime: '2022-02-15T14:45:00-0600',
    price: '0.00',
    priceSold: '0.00',
    paid: 'no',
    amountPaid: '0.00',
    type: '[TEST] myStrength Coaching Initial (INT)',
    appointmentTypeID: 26956973,
    classID: null,
    addonIDs: [],
    category: 'TEST MH',
    duration: '45',
    calendar: '[TEST] myStrength Coach Daisy (INT)',
    calendarID: 6081204,
    certificate: null,
    confirmationPage:
        'https://app.acuityscheduling.com/schedule.php?owner=12218610&action=appt&id%5B%5D=2c40bcc2186114d33214d6a5f66a41fc',
    location: '',
    notes: '',
    timezone: 'America/Los_Angeles',
    calendarTimezone: 'America/Chicago',
    canceled: false,
    canClientCancel: true,
    canClientReschedule: true,
    labels: null,
    forms: [
        {
            id: 1955867,
            name: 'myStrength - Details',
            values: [
                {
                    id: 2828416769,
                    fieldID: 10881467,
                    value: 'Yes',
                    name: 'Do you use assistive devices?',
                },
                {
                    id: 2828416770,
                    fieldID: 10881471,
                    value: 'Yes',
                    name: 'Do you have a video camera?',
                },
                {
                    id: 2828416771,
                    fieldID: 10881504,
                    value: '',
                    name: 'Comments',
                },
            ],
        },
        {
            id: 981847,
            name: 'UserIdentifier',
            values: [
                {
                    id: 2828416772,
                    fieldID: 5243719,
                    value: 'e0e8067e-5ff0-458e-a19d-b774b570f44e',
                    name: 'UUID',
                },
            ],
        },
    ],
    formsText:
        'Name: Nicolas Vrhovski\nPhone: (412) 555-5555\nEmail: nicolas.vrhovski+536@tudip.com\n\nmyStrength - Details\n============\nDo you use assistive devices?: Yes\n\nDo you have a video camera?: Yes\n\nComments: \n\n\n\nUserIdentifier\n============\nUUID: e0e8067e-5ff0-458e-a19d-b774b570f44e\n\n',
    isVerified: false,
    scheduledBy: 'scheduling@livongo.com',
};

export default function scheduler(
    state = {
        appointmentData:
            Arg('mockTest') && process.env.NODE_ENV !== 'production'
                ? mockAcuityData
                : {},
    },
    {type, payload}
) {
    switch (type) {
        case 'APPOINTMENT_DETAILS': {
            return {
                ...state,
                appointmentData: payload,
            };
        }
        case 'SHARED_COACHING': {
            return {
                ...state,
                sharedCoaching: {
                    ...state.sharedCoaching,
                    payload,
                },
            };
        }
        case 'SHARED_COACHING_DATE': {
            return {
                ...state,
                sharedCoaching: {
                    ...state.sharedCoaching,
                    ...payload,
                },
            };
        }
        case 'SHARED_USER': {
            return {
                ...state,
                sharedCoaching: {
                    ...state.sharedCoaching,
                    ...payload,
                },
            };
        }
        case 'ACUITY_DATA': {
            return {
                ...state,
                appointmentData: {
                    ...state.appointmentData,
                    ...payload,
                },
            };
        }
        case 'TIMEZONE': {
            return {
                ...state,
                timezone: payload,
            };
        }
        default:
            return state;
    }
}
