import {createStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import {composeWithDevTools} from 'redux-devtools-extension';
import omit from 'lodash/omit';
import SentryUtils from '@livongo/utilities/system/sentry';
import rootReducer from './reducers';

let store;

const PII_STATE_EXCLUSIONS = [
    'birthDate',
    'email',
    'firstName',
    'lastName',
    'phones',
    'number',
    'numberE164',
    'appointmentTypesInfo',
    'pid',
    'appointmentTypeID',
    'calendar',
    'calendarID',
    'calendarTimezone',
    'confirmationPage',
    'forms',
    'id',
    'scheduledBy',
    'timezone',
    'type',
    'formsText',
    'category',
    'phone',
    'userData',
    'scheduler',
    'appointmentData',
];

const sentryReduxEnhancer = SentryUtils.createReduxEnhancer({
    actionTransformer: action => {
        if (
            action.type === 'TOKEN' ||
            action.type === 'USER_DATA' ||
            action.type === 'APPOINTMENT_DETAILS'
        ) {
            return {
                ...action,
                payload: omit(action.payload, PII_STATE_EXCLUSIONS),
            };
        }

        return action;
    },
    stateTransformer: state => {
        return {
            ...state,
            userData: omit(state.userData, PII_STATE_EXCLUSIONS),
            scheduler: omit(state.scheduler, PII_STATE_EXCLUSIONS),
        };
    },
});

export default function create(initialState) {
    store = createStore(
        rootReducer,
        initialState,
        composeWithDevTools(
            applyMiddleware(thunkMiddleware, promiseMiddleware),
            ...(sentryReduxEnhancer ? [sentryReduxEnhancer] : [])
        )
    );

    return store;
}

export const getStore = () => {
    return store;
};
