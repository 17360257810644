import PropTypes from 'prop-types';
import {useRef, useEffect} from 'react';
import Modal from '@teladoc/pulse/ui/Modal';
import useTransLoader from 'i18n/use-trans-loader';
import TimeTap from '../../utils/TimeTap';
import css from '../SessionDetails.scss';

const CancelModal = ({
    setShowCancelModal,
    calendarId,
    apiSessionToken,
    setSuccessfullyCancelled,
    isMobile,
}) => {
    const {t} = useTransLoader('confirmation');
    const isOpen = true;
    const contentRef = useRef();

    const cancelCoachingSession = async () => {
        const session = apiSessionToken ?? (await TimeTap.getSessionToken());

        await TimeTap.cancelCoachingSessionNonProxyCcmOnly(session, calendarId);

        setShowCancelModal(false);

        if (isMobile) {
            setSuccessfullyCancelled(true);
        }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            contentRef?.current?.focus();
        }, 100);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <div>
            <Modal
                classNameRoot={css.modalTitleMobile}
                isOpen={isOpen}
                onRequestClose={setShowCancelModal}
                title={
                    <h1 ref={contentRef}>{t('cancelSessionConfirmation')}</h1>
                }
                primaryAction={{
                    variant: 'primary',
                    onClick: cancelCoachingSession,
                    children: t(`yes`),
                }}
                secondaryAction={{
                    onClick: setShowCancelModal,
                    variant: 'secondary',
                    children: t(`no`),
                }}
            />
        </div>
    );
};

CancelModal.propTypes = {
    setShowCancelModal: PropTypes.func,
    calendarId: PropTypes.number,
    apiSessionToken: PropTypes.string,
    setSuccessfullyCancelled: PropTypes.func,
    isMobile: PropTypes.bool,
};

export default CancelModal;
