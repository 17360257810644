import PropTypes from 'prop-types';
import {Trans} from 'react-i18next';
import Button from '@teladoc/pulse/ui/Button';
import Modal from '@teladoc/pulse/ui/Modal';
import Arg from '@livongo/arg';
import useTransLoader from 'i18n/use-trans-loader';
import {goToMyStrength} from '../utils/myStrength';
import css from './AcuityError.scss';

const LIVONGO_SUPPORT_EMAIL = 'membersupport@livongo.com';
const ONEAPP_SUPPORT_EMAIL = 'membersupport@teladochealth.com';
const ONEAPP_SDK_SUPPORT_EMAIL = 'mystrengthcoaching@teladochealth.com';

const AcuityError = ({isOpen, toggleModal}) => {
    const {t} = useTransLoader('error');
    const isOneApp = Arg('oneapp');
    const isWebSDK = Arg('oneAppSdk');
    let open = false;

    if (isOpen) {
        open = true;
    }

    const onTryAgainClick = () => {
        if (Arg('referrer')?.includes('mentalhealth')) {
            goToMyStrength();
        } else {
            window.location.reload();
        }
    };

    return (
        <Modal
            title={t('title')}
            isOpen={open}
            classNameContainer={css.container}
            {...(isWebSDK && {
                classNameShim: css.shim,
            })}
            onRequestClose={() => toggleModal(false)}
        >
            <div>
                <p>{t('header')}</p>
                <p>
                    <Trans
                        t={t}
                        i18nKey="tryAgainMessage"
                        components={[
                            /* eslint-disable react/jsx-key */
                            <a
                                href={`mailto:${
                                    isOneApp
                                        ? ONEAPP_SUPPORT_EMAIL
                                        : isWebSDK
                                        ? ONEAPP_SDK_SUPPORT_EMAIL
                                        : LIVONGO_SUPPORT_EMAIL
                                }`}
                            />,
                            /* eslint-enable react/jsx-key */
                        ]}
                        values={{
                            email: isOneApp
                                ? ONEAPP_SUPPORT_EMAIL
                                : isWebSDK
                                ? ONEAPP_SDK_SUPPORT_EMAIL
                                : LIVONGO_SUPPORT_EMAIL,
                        }}
                    />
                </p>
                <div className={css.buttonContainer}>
                    <Button variant="primary" onClick={onTryAgainClick}>
                        {t('tryAgainButton')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

AcuityError.propTypes = {
    isOpen: PropTypes.any,
    toggleModal: PropTypes.func,
};

export default AcuityError;
