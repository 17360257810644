import isUndefined from 'lodash/isUndefined';
import Arg from '@livongo/arg';

const returnPath = Arg('returnPath') || '';
const isOneApp = Arg('oneapp');
const isWebSDK = Arg('oneAppSdk');
const isFromMobileApp =
    !isUndefined(Arg('isMobileWebView')) && !isUndefined(Arg('mobileplatform'));

export const goToMyStrength = () => {
    if (isOneApp) {
        window.open(
            `${process.env.MYSTRENGTH_ONEAPP_URL}${decodeURIComponent(
                returnPath
            )}${
                isFromMobileApp
                    ? `&isMobileWebView=${Arg(
                          'isMobileWebView'
                      )}&mobileplatform=${Arg('mobileplatform')}`
                    : ''
            }`,
            '_self'
        );
    } else if (isWebSDK) {
        const oneAppReturnUrl = `url:${returnPath.replaceAll('%%', '/')}`;
        const message = {redirect: oneAppReturnUrl};

        window.top.postMessage(message, '*');
        window.location.href = oneAppReturnUrl;

        return;
    } else if (returnPath) {
        window.open(
            `${process.env.MYSTRENGTH_URL}${decodeURIComponent(returnPath)}`,
            '_self'
        );
    }
};
