export default function userData(
    state = {
        userData: {
            firstName: '',
            lastName: '',
            email: '',
            phones: [
                {
                    countryCode: 'US',
                    number: '',
                },
            ],
        },
        auth: {
            uuid: '',
            token: '',
        },
        error: null,
    },
    {type, payload}
) {
    switch (type) {
        case 'TOKEN': {
            return {
                ...state,
                token: payload,
            };
        }
        case 'AUTH': {
            return {
                ...state,
                auth: payload,
            };
        }
        case 'USER_DATA': {
            return {
                ...state,
                userData: payload,
                fetched: true,
            };
        }
        case 'VIEW': {
            return {
                ...state,
                view: payload,
            };
        }
        case 'ERROR': {
            return {
                ...state,
                error: payload,
            };
        }
        default:
            return state;
    }
}
